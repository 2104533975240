import React from 'react'

export default function Contact() {
  return (
    <div className='text-white p-3 m-5'>
        <h1 className='font-bold text-xl'>Contact</h1>
        <p className=''>
            Feel free to contact me at <a href="mailto:etc@unknownstudio.com">etc@unknownstudio.com</a>.
            I apologize for the lack of a contact form, but I don't want to deal with the spam.
            Sorry if I don't respond, I'm probably busy. {"<3"}
        </p>
    </div>
  )
}
