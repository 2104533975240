import React from "react";
import logo from "../logo.svg";
import budgy from "../resources/apps/budgy/budgyCard_1820x1024.svg";
import txture from "../resources/apps/txture/txtureCard_1820x1024.svg";
import BuyMeACoffee from "../components/BuyMeACoffee";
import BgInteractable from "../components/BgInteractable";
import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

export default function Home() {
  var apps = [
    {
      name: "Budgy",
      description: "A simple budgeting app.",
      image: budgy,
      href: "https://budgy.eef.app",
    },
    {
      name: "[txt]ure",
      description: "ASCII games and art.",
      image: txture,
      href: "https://txture.eef.app",
    }
  ];

  var screenWidth = window.innerWidth;
  var screenHeight = window.innerHeight;

  var triangleWidth = 5;
  var triangleHeight = (triangleWidth * Math.sqrt(3)) / 2;
  var triangleSpacing = 30;

  var triangleRows = Math.ceil(
    screenHeight / (triangleHeight + triangleSpacing)
  );
  var triangleCols = Math.ceil(screenWidth / (triangleWidth + triangleSpacing));

  return (
    <div className="m-7 grow flex flex-col items-center text-lg text-white">
      <div className="text-2xl">
        Welcome to{" "}
        <span className="font-pecita text-4xl text-caribbean">eef.app</span>
      </div>
      <div className="text-xl">
        This is a collection of apps made by me,{" "}
        <a href="https://ethantchristensen.com">
          Ethan "<span className="font-pecita text-2xl">eef</span>" Christensen
        </a>
        .
      </div>
      <div>
        My aim with these apps is to create tools or entertainment for free,
        without ads, and without selling your data. I hope you enjoy them!
      </div>

      {/* Main apps list */}
      <ul className="flex gap-3 flex-wrap py-10">
        {apps.map((app) => (
          <li
            key={app.name}
            className="rounded-lg p-3 bg-caribbean text-gray-800 flex flex-col gap-1"
          >
            <div className="font-bold text-xl">{app.name}</div>
            <div className="font-semibold">{app.description}</div>
            <motion.a
              whileHover={{ scale: 1.05 }}
              transition={{
                type: "spring",
                damping: 5,
              }}
              href={app.href}
            >
              <img className="rounded" src={app.image} width={200} />
            </motion.a>
          </li>
        ))}
      </ul>

      <div className="fixed inset-0 -z-50">
        {(() => (
          <BgInteractable
            numRows={triangleRows}
            numCols={triangleCols}
            spacing={triangleSpacing}
            triangleWidth={triangleWidth}
            r={9}
            g={195}
            b={170}
            minA={0.1}
            maxA={1}
          />
        ))()}
      </div>

      <div className="mt-auto flex flex-col lg:flex-row gap-2 items-center">
        Even though these are free, I still have to pay for hosting. If you
        would like to support me, you can <BuyMeACoffee type="button" />
      </div>
    </div>
  );
}
