import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

// Reference: https://www.youtube.com/watch?v=p4a0WGMTQaU
export default function BgInteractable({
  numRows = 10,
  numCols = 10,
  spacing = 30,
  mouseRadius = 300,
  triangleWidth = 10,
  r = 255,
  g = 255,
  b = 255,
  minA = 0.1,
  maxA = 1,
}) {
  const canvasRef = useRef(null);

  const w = (triangleWidth + spacing) * numCols;
  const h = ((triangleWidth * Math.sqrt(3)) / 2 + spacing) * numRows;

  let animFrameId;
  let particles;
  let mouse = {
    x: null,
    y: null,
  };
  let ctx;

  function init() {
    createParticles();
    animLoop();
  }

  function createParticles() {
    particles = [];

    for (let i = 0; i < numRows; i++) {
      for (let j = 0; j < numCols; j++) {
        let x = (triangleWidth + spacing) * j;
        let y = ((triangleWidth * Math.sqrt(3)) / 2 + spacing) * i;
        particles.push(new Particle(x, y, i, j));
      }
    }
  }

  function animLoop() {
    ctx.clearRect(0, 0, w, h);
    drawScene();

    // Request new frame if not unmounted
    animFrameId = requestAnimationFrame(animLoop);
  }

  function drawScene() {
    for (let i = 0; i < particles.length; i++) {
      particles[i].update();
      particles[i].draw();
    }
  }

  function mouseMove(e) {
    mouse.x = e.x;
    mouse.y = e.y;
  }

  class Particle {
    constructor(x, y, row, col) {
      this.x = x;
      this.y = y;
      this.row = row;
      this.col = col;
      this.angle = 0;
      this.alpha = minA;
    }

    draw() {
      var rads = (this.angle * Math.PI) / 180;

      ctx.fillStyle = `rgba(${r},${g},${b},${this.alpha})`;
      ctx.translate(this.x, this.y);
      ctx.rotate(rads);
      ctx.beginPath();
      ctx.moveTo(triangleWidth * Math.cos(0), triangleWidth * Math.sin(0));
      ctx.lineTo(
        triangleWidth * Math.cos((2 * Math.PI) / 3),
        triangleWidth * Math.sin((2 * Math.PI) / 3)
      );
      ctx.lineTo(
        triangleWidth * Math.cos((4 * Math.PI) / 3),
        triangleWidth * Math.sin((4 * Math.PI) / 3)
      );
      ctx.closePath();
      ctx.fill();
      ctx.rotate(-rads);
      ctx.translate(-this.x, -this.y);
    }

    update() {
      var diff = {
        x: mouse.x - this.x,
        y: mouse.y - this.y,
      };
      var dist = Math.sqrt(diff.x * diff.x + diff.y * diff.y);

      this.x = (triangleWidth + spacing) * this.col;
      this.y = ((triangleWidth * Math.sqrt(3)) / 2 + spacing) * this.row;
      this.angle = (this.row + this.col) * 10;
      this.alpha = minA;

      if (dist < mouseRadius) {
        this.x += diff.x * 1.03 ** -dist;
        this.y += diff.y * 1.03 ** -dist;
        this.angle += 180 * 1.01 ** -dist;
      }
      this.alpha +=
        (maxA - minA) * 1.02 ** -(dist < 50 ? dist / 2 : dist) + minA;
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    ctx = canvas.getContext("2d");

    init();
    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
      cancelAnimationFrame(animFrameId);
    };
  }, [
    numRows,
    numCols,
    spacing,
    mouseRadius,
    triangleWidth,
    r,
    g,
    b,
    minA,
    maxA,
  ]);

  return <canvas id="bgInteractable" ref={canvasRef} width={w} height={h} />;
}
