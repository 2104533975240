import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../logo.svg";
import BlobScene from "../components/BlobScene";

import Nav from "../components/Nav";
import Footer from "../components/Footer";

export default function Layout() {

  return (
    <div className="h-screen flex flex-col">
      <Nav />
      <Outlet />
      <Footer />
    </div>
  )
}
