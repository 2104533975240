import React from 'react'

export default function About() {
  return (
    <div className='text-white p-3 m-5'>
        <h1 className='font-bold text-xl'>About</h1>
        <p className=''>
            This is a collection of apps made by me, <a href="https://ethantchristensen.com">Ethan Christensen</a>.
            This is a series of personal projects that I am working on in my free time.
            All apps are free to use.
            I started this when I came up with the idea for my app <a href="https://budgy.eef.app">Budgy</a>.
            I wanted to make a simple budgeting app that was easy to use and free.
            I ended up with this sandbox for my apps, experiments, projects, etc.
            It is a work in progress, and I will be adding more apps as I make them.
            Everything is free, ad-free, and privacy-respecting.
            I hate how everything has ads or costs money, so I wanted to make something that was free.
            I hope you enjoy!
        </p>
    </div>
  )
}
