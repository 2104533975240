import { useRef, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, Link } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import logoAnim from "../resources/animations/eefLogo/data.json";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const logoAnimRef = useRef(null);

  const [navigation, setNavigation] = useState([
    { name: "Apps", href: "/", current: true },
    { name: "Contact", href: "/contact", current: false },
    { name: "About", href: "/about", current: false },
  ]);

  const location = useLocation();

  useEffect(() => {
    var temp = structuredClone(navigation);
    temp.forEach((item) => {
      item.current = item.href === location.pathname;
    });
    setNavigation(temp);
  }, [location]);

  function logoAnimEvent(event) {
    switch (event) {
      case "complete":
        if (logoAnimRef.current.playDirection === -1) {
          logoAnimRef.current.setDirection(1);
          logoAnimRef.current.play();
        } else {
          var duration = 10000; // 10 seconds
          setTimeout(() => {
            logoAnimRef.current.setDirection(-1);
            logoAnimRef.current.play();
          }, duration);
        }
        break;

      default:
        break;
    }
  }

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <span className="font-pecita text-5xl text-caribbean">
                    <Link to={"/"}>
                      <Player
                        lottieRef={(ref) => {
                          logoAnimRef.current = ref;
                        }}
                        autoplay
                        keepLastFrame
                        src={logoAnim}
                        className="h-[60px] w-[100px]"
                        onEvent={logoAnimEvent}
                      />
                    </Link>
                  </span>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="h-full flex items-center space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
